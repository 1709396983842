/* tslint:disable */
/* eslint-disable */
/**
 * HiQ.Marketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ContactMethod = {
    Email: 'Email',
    Mobile: 'Mobile',
    Telephone: 'Telephone'
} as const;

export type ContactMethod = typeof ContactMethod[keyof typeof ContactMethod];


/**
 * 
 * @export
 * @interface CreateCampaignSubmissionCommand
 */
export interface CreateCampaignSubmissionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'campaignTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'store': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'selloutDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'claimedDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'tyreSize': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCampaignSubmissionCommand
     */
    'numberOfTyres': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCampaignSubmissionCommand
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'sellOutInvoiceRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignSubmissionCommand
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @interface CreateContactRequestCommand
 */
export interface CreateContactRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'store': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactRequestCommand
     */
    'onlyContactViaPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactRequestCommand
     */
    'receiveFutureCommunications': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'comments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequestCommand
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @interface CreateFleetContactRequestCommand
 */
export interface CreateFleetContactRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'preferredContactMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'industry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'numberVehiclesInFleet': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'typesOfVehicles': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'geographicCoverage': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFleetContactRequestCommand
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @interface CreateFranchiseApplicationCommand
 */
export interface CreateFranchiseApplicationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'idDocumentRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'city': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFranchiseApplicationCommand
     */
    'noBuilding': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'noBuildingDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFranchiseApplicationCommand
     */
    'existingBuilding': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'existingBuildingDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFranchiseApplicationCommand
     */
    'existingBusiness': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'existingBusinessDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFranchiseApplicationCommand
     */
    'hasExperience': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'experienceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'financingDetails': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFranchiseApplicationCommand
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @interface CreateQuoteRequestCommand
 */
export interface CreateQuoteRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'store': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateQuoteRequestCommand
     */
    'receiveFutureCommunications': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'comments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'vehicleManufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'vehicleModel': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'vehicleYear': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuoteRequestCommand
     */
    'quantity': number;
    /**
     * 
     * @type {Array<QuoteRequestProductCreateModel>}
     * @memberof CreateQuoteRequestCommand
     */
    'quoteRequestProducts': Array<QuoteRequestProductCreateModel>;
    /**
     * 
     * @type {Array<QuoteRequestServiceCreateModel>}
     * @memberof CreateQuoteRequestCommand
     */
    'quoteRequestServices': Array<QuoteRequestServiceCreateModel>;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestCommand
     */
    'googleRecaptchaResponse': string;
    /**
     * 
     * @type {Array<ContactMethod>}
     * @memberof CreateQuoteRequestCommand
     */
    'preferredContactMethods': Array<ContactMethod>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateQuoteRequestCommand
     */
    'receiveInsurerCommunications': boolean;
}
/**
 * 
 * @export
 * @interface FilePath
 */
export interface FilePath {
    /**
     * 
     * @type {string}
     * @memberof FilePath
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilePath
     */
    'absolutePath': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GetTyresQueryOrderByField = {
    Brand: 'Brand',
    Price: 'Price'
} as const;

export type GetTyresQueryOrderByField = typeof GetTyresQueryOrderByField[keyof typeof GetTyresQueryOrderByField];


/**
 * Implicit wrapping of types that serialize to non-complex values.
 * @export
 * @interface JsonResponseOfGuid
 */
export interface JsonResponseOfGuid {
    /**
     * 
     * @type {string}
     * @memberof JsonResponseOfGuid
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderByDirection = {
    Ascending: 'Ascending',
    Descending: 'Descending'
} as const;

export type OrderByDirection = typeof OrderByDirection[keyof typeof OrderByDirection];


/**
 * 
 * @export
 * @interface PagedResultOfStoreListViewModel
 */
export interface PagedResultOfStoreListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfStoreListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfStoreListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfStoreListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfStoreListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<StoreListViewModel>}
     * @memberof PagedResultOfStoreListViewModel
     */
    'data': Array<StoreListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfString
 */
export interface PagedResultOfString {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfString
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfString
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfString
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfString
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PagedResultOfString
     */
    'data': Array<string>;
}
/**
 * 
 * @export
 * @interface PagedResultOfTyreBrandListViewModel
 */
export interface PagedResultOfTyreBrandListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreBrandListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreBrandListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreBrandListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreBrandListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<TyreBrandListViewModel>}
     * @memberof PagedResultOfTyreBrandListViewModel
     */
    'data': Array<TyreBrandListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfTyreCategoryListViewModel
 */
export interface PagedResultOfTyreCategoryListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreCategoryListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreCategoryListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreCategoryListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreCategoryListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<TyreCategoryListViewModel>}
     * @memberof PagedResultOfTyreCategoryListViewModel
     */
    'data': Array<TyreCategoryListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfTyreListViewModel
 */
export interface PagedResultOfTyreListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfTyreListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<TyreListViewModel>}
     * @memberof PagedResultOfTyreListViewModel
     */
    'data': Array<TyreListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfVehicleManufacturerListViewModel
 */
export interface PagedResultOfVehicleManufacturerListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleManufacturerListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleManufacturerListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleManufacturerListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleManufacturerListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VehicleManufacturerListViewModel>}
     * @memberof PagedResultOfVehicleManufacturerListViewModel
     */
    'data': Array<VehicleManufacturerListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfVehicleModelListViewModel
 */
export interface PagedResultOfVehicleModelListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VehicleModelListViewModel>}
     * @memberof PagedResultOfVehicleModelListViewModel
     */
    'data': Array<VehicleModelListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfVehicleModelYearListViewModel
 */
export interface PagedResultOfVehicleModelYearListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelYearListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelYearListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelYearListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleModelYearListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VehicleModelYearListViewModel>}
     * @memberof PagedResultOfVehicleModelYearListViewModel
     */
    'data': Array<VehicleModelYearListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfVehicleSpecificationListViewModel
 */
export interface PagedResultOfVehicleSpecificationListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleSpecificationListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleSpecificationListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleSpecificationListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfVehicleSpecificationListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<VehicleSpecificationListViewModel>}
     * @memberof PagedResultOfVehicleSpecificationListViewModel
     */
    'data': Array<VehicleSpecificationListViewModel>;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface QuoteRequestProductCreateModel
 */
export interface QuoteRequestProductCreateModel {
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestProductCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestProductCreateModel
     */
    'additionalDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface QuoteRequestServiceCreateModel
 */
export interface QuoteRequestServiceCreateModel {
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestServiceCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestServiceCreateModel
     */
    'additionalDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreListViewModel
 */
export interface StoreListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'tradingName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'physicalAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'physicalAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'physicalAddressCity': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'physicalAddressPostCode': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'regionName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'telephoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'googleBusinessId'?: string | null;
    /**
     * 
     * @type {Array<StoreStoreServiceListViewModel>}
     * @memberof StoreListViewModel
     */
    'storeServices': Array<StoreStoreServiceListViewModel>;
    /**
     * 
     * @type {Array<StoreStoreTradingDayListViewModel>}
     * @memberof StoreListViewModel
     */
    'storeTradingDays': Array<StoreStoreTradingDayListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModel
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModel
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'whatsappBusinessNo': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'storeId'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreStoreServiceListViewModel
 */
export interface StoreStoreServiceListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreStoreServiceListViewModel
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface StoreStoreTradingDayListViewModel
 */
export interface StoreStoreTradingDayListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreStoreTradingDayListViewModel
     */
    'dayOfWeek': string;
    /**
     * 
     * @type {number}
     * @memberof StoreStoreTradingDayListViewModel
     */
    'dayOfWeekNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof StoreStoreTradingDayListViewModel
     */
    'dayOfWeekActive': boolean;
    /**
     * 
     * @type {TimeSpan}
     * @memberof StoreStoreTradingDayListViewModel
     */
    'openingTime': TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof StoreStoreTradingDayListViewModel
     */
    'closingTime': TimeSpan;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'ticks': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'days': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'hours': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'milliseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'microseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'nanoseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'minutes': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'seconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalDays': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalHours': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMilliseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMicroseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalNanoseconds': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalSeconds': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TreadPatternMetaType = {
    Document: 'Document',
    Image: 'Image',
    MetaDescription: 'MetaDescription',
    MetaTitle: 'MetaTitle',
    MetaTag: 'MetaTag',
    Thumbnail: 'Thumbnail',
    Video: 'Video'
} as const;

export type TreadPatternMetaType = typeof TreadPatternMetaType[keyof typeof TreadPatternMetaType];


/**
 * 
 * @export
 * @interface TyreBrandListViewModel
 */
export interface TyreBrandListViewModel {
    /**
     * 
     * @type {number}
     * @memberof TyreBrandListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TyreBrandListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TyreBrandListViewModel
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface TyreCategoryListViewModel
 */
export interface TyreCategoryListViewModel {
    /**
     * 
     * @type {number}
     * @memberof TyreCategoryListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TyreCategoryListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TyreCategoryListViewModel
     */
    'parentTyreCategoryTitle': string;
}
/**
 * 
 * @export
 * @interface TyreListViewModel
 */
export interface TyreListViewModel {
    /**
     * 
     * @type {number}
     * @memberof TyreListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'purchaseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'sectionWidth': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'aspectRatio': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'rimSize': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'loadIndex': string;
    /**
     * 
     * @type {string}
     * @memberof TyreListViewModel
     */
    'speedIndex': string;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'mfs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'rof': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'radial': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'bias': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'lhd': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'xl': boolean;
    /**
     * 
     * @type {number}
     * @memberof TyreListViewModel
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'sellOnSurface'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'inStock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TyreListViewModel
     */
    'isImperial': boolean;
    /**
     * 
     * @type {TyreTreadPatternViewModel}
     * @memberof TyreListViewModel
     */
    'tyreTreadPattern': TyreTreadPatternViewModel;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternMetaListViewModel
 */
export interface TyreTreadPatternMetaListViewModel {
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternMetaListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternMetaListViewModel
     */
    'value': string;
    /**
     * 
     * @type {TreadPatternMetaType}
     * @memberof TyreTreadPatternMetaListViewModel
     */
    'treadPatternMetaTypeId': TreadPatternMetaType;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternViewModel
 */
export interface TyreTreadPatternViewModel {
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternViewModel
     */
    'facts': string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternViewModel
     */
    'title': string;
    /**
     * 
     * @type {TyreBrandListViewModel}
     * @memberof TyreTreadPatternViewModel
     */
    'tyreBrand': TyreBrandListViewModel;
    /**
     * 
     * @type {TyreCategoryListViewModel}
     * @memberof TyreTreadPatternViewModel
     */
    'tyreCategory': TyreCategoryListViewModel;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternViewModel
     */
    'slug': string;
    /**
     * 
     * @type {Array<TyreTreadPatternMetaListViewModel>}
     * @memberof TyreTreadPatternViewModel
     */
    'treadPatternMetas': Array<TyreTreadPatternMetaListViewModel>;
}
/**
 * 
 * @export
 * @interface VehicleManufacturerListViewModel
 */
export interface VehicleManufacturerListViewModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleManufacturerListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleManufacturerListViewModel
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface VehicleModelListViewModel
 */
export interface VehicleModelListViewModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelListViewModel
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface VehicleModelYearListViewModel
 */
export interface VehicleModelYearListViewModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelYearListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelYearListViewModel
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface VehicleSpecificationListViewModel
 */
export interface VehicleSpecificationListViewModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecificationListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecificationListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecificationListViewModel
     */
    'tyreSize': string;
}

/**
 * CampaignSubmissionsApi - axios parameter creator
 * @export
 */
export const CampaignSubmissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateCampaignSubmissionCommand} [createCampaignSubmissionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignSubmissionPost: async (createCampaignSubmissionCommand?: CreateCampaignSubmissionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign-submission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCampaignSubmissionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignSubmissionsApi - functional programming interface
 * @export
 */
export const CampaignSubmissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignSubmissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateCampaignSubmissionCommand} [createCampaignSubmissionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampaignSubmissionPost(createCampaignSubmissionCommand?: CreateCampaignSubmissionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampaignSubmissionPost(createCampaignSubmissionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignSubmissionsApi - factory interface
 * @export
 */
export const CampaignSubmissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignSubmissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateCampaignSubmissionCommand} [createCampaignSubmissionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampaignSubmissionPost(createCampaignSubmissionCommand?: CreateCampaignSubmissionCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiCampaignSubmissionPost(createCampaignSubmissionCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignSubmissionsApi - object-oriented interface
 * @export
 * @class CampaignSubmissionsApi
 * @extends {BaseAPI}
 */
export class CampaignSubmissionsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateCampaignSubmissionCommand} [createCampaignSubmissionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignSubmissionsApi
     */
    public apiCampaignSubmissionPost(createCampaignSubmissionCommand?: CreateCampaignSubmissionCommand, options?: AxiosRequestConfig) {
        return CampaignSubmissionsApiFp(this.configuration).apiCampaignSubmissionPost(createCampaignSubmissionCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactRequestsApi - axios parameter creator
 * @export
 */
export const ContactRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateContactRequestCommand} [createContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactRequestPost: async (createContactRequestCommand?: CreateContactRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contact-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactRequestsApi - functional programming interface
 * @export
 */
export const ContactRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateContactRequestCommand} [createContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactRequestPost(createContactRequestCommand?: CreateContactRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactRequestPost(createContactRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactRequestsApi - factory interface
 * @export
 */
export const ContactRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateContactRequestCommand} [createContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactRequestPost(createContactRequestCommand?: CreateContactRequestCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiContactRequestPost(createContactRequestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactRequestsApi - object-oriented interface
 * @export
 * @class ContactRequestsApi
 * @extends {BaseAPI}
 */
export class ContactRequestsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateContactRequestCommand} [createContactRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactRequestsApi
     */
    public apiContactRequestPost(createContactRequestCommand?: CreateContactRequestCommand, options?: AxiosRequestConfig) {
        return ContactRequestsApiFp(this.configuration).apiContactRequestPost(createContactRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesViewRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/view/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet: async (relativePath: string, width: number, height: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'relativePath', relativePath)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'height', height)
            const localVarPath = `/files/{width}/{height}/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)))
                .replace(`{${"width"}}`, encodeURIComponent(String(width)))
                .replace(`{${"height"}}`, encodeURIComponent(String(height)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesViewRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesWidthHeightRelativePathGet(relativePath, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(file?: File, options?: any): AxiosPromise<FilePath> {
            return localVarFp.filesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesViewRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: any): AxiosPromise<string> {
            return localVarFp.filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(file?: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesViewRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FleetContactRequestsApi - axios parameter creator
 * @export
 */
export const FleetContactRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateFleetContactRequestCommand} [createFleetContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFleetContactRequestPost: async (createFleetContactRequestCommand?: CreateFleetContactRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fleet-contact-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFleetContactRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FleetContactRequestsApi - functional programming interface
 * @export
 */
export const FleetContactRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FleetContactRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateFleetContactRequestCommand} [createFleetContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFleetContactRequestPost(createFleetContactRequestCommand?: CreateFleetContactRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFleetContactRequestPost(createFleetContactRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FleetContactRequestsApi - factory interface
 * @export
 */
export const FleetContactRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FleetContactRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateFleetContactRequestCommand} [createFleetContactRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFleetContactRequestPost(createFleetContactRequestCommand?: CreateFleetContactRequestCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiFleetContactRequestPost(createFleetContactRequestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FleetContactRequestsApi - object-oriented interface
 * @export
 * @class FleetContactRequestsApi
 * @extends {BaseAPI}
 */
export class FleetContactRequestsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateFleetContactRequestCommand} [createFleetContactRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetContactRequestsApi
     */
    public apiFleetContactRequestPost(createFleetContactRequestCommand?: CreateFleetContactRequestCommand, options?: AxiosRequestConfig) {
        return FleetContactRequestsApiFp(this.configuration).apiFleetContactRequestPost(createFleetContactRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FranchiseApplicationsApi - axios parameter creator
 * @export
 */
export const FranchiseApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateFranchiseApplicationCommand} [createFranchiseApplicationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFranchiseApplicationPost: async (createFranchiseApplicationCommand?: CreateFranchiseApplicationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/franchise-application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFranchiseApplicationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FranchiseApplicationsApi - functional programming interface
 * @export
 */
export const FranchiseApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FranchiseApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateFranchiseApplicationCommand} [createFranchiseApplicationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFranchiseApplicationPost(createFranchiseApplicationCommand?: CreateFranchiseApplicationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFranchiseApplicationPost(createFranchiseApplicationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FranchiseApplicationsApi - factory interface
 * @export
 */
export const FranchiseApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FranchiseApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateFranchiseApplicationCommand} [createFranchiseApplicationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFranchiseApplicationPost(createFranchiseApplicationCommand?: CreateFranchiseApplicationCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiFranchiseApplicationPost(createFranchiseApplicationCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FranchiseApplicationsApi - object-oriented interface
 * @export
 * @class FranchiseApplicationsApi
 * @extends {BaseAPI}
 */
export class FranchiseApplicationsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateFranchiseApplicationCommand} [createFranchiseApplicationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FranchiseApplicationsApi
     */
    public apiFranchiseApplicationPost(createFranchiseApplicationCommand?: CreateFranchiseApplicationCommand, options?: AxiosRequestConfig) {
        return FranchiseApplicationsApiFp(this.configuration).apiFranchiseApplicationPost(createFranchiseApplicationCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuoteRequestsApi - axios parameter creator
 * @export
 */
export const QuoteRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateQuoteRequestCommand} [createQuoteRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuoteRequestPost: async (createQuoteRequestCommand?: CreateQuoteRequestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/quote-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuoteRequestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuoteRequestsApi - functional programming interface
 * @export
 */
export const QuoteRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuoteRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateQuoteRequestCommand} [createQuoteRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuoteRequestPost(createQuoteRequestCommand?: CreateQuoteRequestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuoteRequestPost(createQuoteRequestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuoteRequestsApi - factory interface
 * @export
 */
export const QuoteRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuoteRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateQuoteRequestCommand} [createQuoteRequestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuoteRequestPost(createQuoteRequestCommand?: CreateQuoteRequestCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiQuoteRequestPost(createQuoteRequestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuoteRequestsApi - object-oriented interface
 * @export
 * @class QuoteRequestsApi
 * @extends {BaseAPI}
 */
export class QuoteRequestsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateQuoteRequestCommand} [createQuoteRequestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteRequestsApi
     */
    public apiQuoteRequestPost(createQuoteRequestCommand?: CreateQuoteRequestCommand, options?: AxiosRequestConfig) {
        return QuoteRequestsApiFp(this.configuration).apiQuoteRequestPost(createQuoteRequestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SitemapApi - axios parameter creator
 * @export
 */
export const SitemapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitemapGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sitemap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitemapApi - functional programming interface
 * @export
 */
export const SitemapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitemapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSitemapGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitemapGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitemapApi - factory interface
 * @export
 */
export const SitemapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitemapApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitemapGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiSitemapGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitemapApi - object-oriented interface
 * @export
 * @class SitemapApi
 * @extends {BaseAPI}
 */
export class SitemapApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapApi
     */
    public apiSitemapGet(options?: AxiosRequestConfig) {
        return SitemapApiFp(this.configuration).apiSitemapGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [tradingName] 
         * @param {string} [regionName] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoreGet: async (pageNo?: number, pageSize?: number, tradingName?: string, regionName?: string, city?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (tradingName !== undefined) {
                localVarQueryParameter['tradingName'] = tradingName;
            }

            if (regionName !== undefined) {
                localVarQueryParameter['regionName'] = regionName;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresCitiesGet: async (pageNo?: number, pageSize?: number, region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresNearMeGet: async (pageNo?: number, pageSize?: number, longitude?: number, latitude?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/near-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresRegionsGet: async (pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [tradingName] 
         * @param {string} [regionName] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoreGet(pageNo?: number, pageSize?: number, tradingName?: string, regionName?: string, city?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfStoreListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoreGet(pageNo, pageSize, tradingName, regionName, city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoresCitiesGet(pageNo?: number, pageSize?: number, region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoresCitiesGet(pageNo, pageSize, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoresNearMeGet(pageNo?: number, pageSize?: number, longitude?: number, latitude?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfStoreListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoresNearMeGet(pageNo, pageSize, longitude, latitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoresRegionsGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoresRegionsGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [tradingName] 
         * @param {string} [regionName] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoreGet(pageNo?: number, pageSize?: number, tradingName?: string, regionName?: string, city?: string, options?: any): AxiosPromise<PagedResultOfStoreListViewModel> {
            return localVarFp.apiStoreGet(pageNo, pageSize, tradingName, regionName, city, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresCitiesGet(pageNo?: number, pageSize?: number, region?: string, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiStoresCitiesGet(pageNo, pageSize, region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {number} [longitude] 
         * @param {number} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresNearMeGet(pageNo?: number, pageSize?: number, longitude?: number, latitude?: number, options?: any): AxiosPromise<PagedResultOfStoreListViewModel> {
            return localVarFp.apiStoresNearMeGet(pageNo, pageSize, longitude, latitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresRegionsGet(pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiStoresRegionsGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [tradingName] 
     * @param {string} [regionName] 
     * @param {string} [city] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoreGet(pageNo?: number, pageSize?: number, tradingName?: string, regionName?: string, city?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoreGet(pageNo, pageSize, tradingName, regionName, city, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoresCitiesGet(pageNo?: number, pageSize?: number, region?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoresCitiesGet(pageNo, pageSize, region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {number} [longitude] 
     * @param {number} [latitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoresNearMeGet(pageNo?: number, pageSize?: number, longitude?: number, latitude?: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoresNearMeGet(pageNo, pageSize, longitude, latitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoresRegionsGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoresRegionsGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TyreBrandsApi - axios parameter creator
 * @export
 */
export const TyreBrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreBrandGet: async (pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tyre-brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TyreBrandsApi - functional programming interface
 * @export
 */
export const TyreBrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TyreBrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyreBrandGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfTyreBrandListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyreBrandGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TyreBrandsApi - factory interface
 * @export
 */
export const TyreBrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TyreBrandsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreBrandGet(pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfTyreBrandListViewModel> {
            return localVarFp.apiTyreBrandGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TyreBrandsApi - object-oriented interface
 * @export
 * @class TyreBrandsApi
 * @extends {BaseAPI}
 */
export class TyreBrandsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreBrandsApi
     */
    public apiTyreBrandGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return TyreBrandsApiFp(this.configuration).apiTyreBrandGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TyreCategoriesApi - axios parameter creator
 * @export
 */
export const TyreCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreCategoryGet: async (pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tyre-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TyreCategoriesApi - functional programming interface
 * @export
 */
export const TyreCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TyreCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyreCategoryGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfTyreCategoryListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyreCategoryGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TyreCategoriesApi - factory interface
 * @export
 */
export const TyreCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TyreCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreCategoryGet(pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfTyreCategoryListViewModel> {
            return localVarFp.apiTyreCategoryGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TyreCategoriesApi - object-oriented interface
 * @export
 * @class TyreCategoriesApi
 * @extends {BaseAPI}
 */
export class TyreCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreCategoriesApi
     */
    public apiTyreCategoryGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return TyreCategoriesApiFp(this.configuration).apiTyreCategoryGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TyreTreadPatternsApi - axios parameter creator
 * @export
 */
export const TyreTreadPatternsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreTreadPatternSlugGet: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('apiTyreTreadPatternSlugGet', 'slug', slug)
            const localVarPath = `/api/tyre-tread-pattern/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TyreTreadPatternsApi - functional programming interface
 * @export
 */
export const TyreTreadPatternsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TyreTreadPatternsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyreTreadPatternSlugGet(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyreTreadPatternViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyreTreadPatternSlugGet(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TyreTreadPatternsApi - factory interface
 * @export
 */
export const TyreTreadPatternsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TyreTreadPatternsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreTreadPatternSlugGet(slug: string, options?: any): AxiosPromise<TyreTreadPatternViewModel> {
            return localVarFp.apiTyreTreadPatternSlugGet(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TyreTreadPatternsApi - object-oriented interface
 * @export
 * @class TyreTreadPatternsApi
 * @extends {BaseAPI}
 */
export class TyreTreadPatternsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreTreadPatternsApi
     */
    public apiTyreTreadPatternSlugGet(slug: string, options?: AxiosRequestConfig) {
        return TyreTreadPatternsApiFp(this.configuration).apiTyreTreadPatternSlugGet(slug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TyresApi - axios parameter creator
 * @export
 */
export const TyresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {OrderByDirection} [orderByDirection] 
         * @param {GetTyresQueryOrderByField} [orderByField] 
         * @param {number} [minimumPrice] 
         * @param {number} [maximumPrice] 
         * @param {boolean} [isRunFlat] 
         * @param {Array<number>} [tyreBrandIds] 
         * @param {Array<number>} [tyreCategoryIds] 
         * @param {string} [sectionWidth] 
         * @param {string} [aspectRatio] 
         * @param {string} [rimSize] 
         * @param {number} [treadPatternId] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreGet: async (pageNo?: number, pageSize?: number, orderByDirection?: OrderByDirection, orderByField?: GetTyresQueryOrderByField, minimumPrice?: number, maximumPrice?: number, isRunFlat?: boolean, tyreBrandIds?: Array<number>, tyreCategoryIds?: Array<number>, sectionWidth?: string, aspectRatio?: string, rimSize?: string, treadPatternId?: number, isImperial?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tyre`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderByDirection !== undefined) {
                localVarQueryParameter['orderByDirection'] = orderByDirection;
            }

            if (orderByField !== undefined) {
                localVarQueryParameter['orderByField'] = orderByField;
            }

            if (minimumPrice !== undefined) {
                localVarQueryParameter['minimumPrice'] = minimumPrice;
            }

            if (maximumPrice !== undefined) {
                localVarQueryParameter['maximumPrice'] = maximumPrice;
            }

            if (isRunFlat !== undefined) {
                localVarQueryParameter['isRunFlat'] = isRunFlat;
            }

            if (tyreBrandIds) {
                localVarQueryParameter['tyreBrandIds'] = tyreBrandIds;
            }

            if (tyreCategoryIds) {
                localVarQueryParameter['tyreCategoryIds'] = tyreCategoryIds;
            }

            if (sectionWidth !== undefined) {
                localVarQueryParameter['sectionWidth'] = sectionWidth;
            }

            if (aspectRatio !== undefined) {
                localVarQueryParameter['aspectRatio'] = aspectRatio;
            }

            if (rimSize !== undefined) {
                localVarQueryParameter['rimSize'] = rimSize;
            }

            if (treadPatternId !== undefined) {
                localVarQueryParameter['treadPatternId'] = treadPatternId;
            }

            if (isImperial !== undefined) {
                localVarQueryParameter['isImperial'] = isImperial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} sectionWidth 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresAspectratiosSectionWidthGet: async (sectionWidth: string, pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionWidth' is not null or undefined
            assertParamExists('apiTyresAspectratiosSectionWidthGet', 'sectionWidth', sectionWidth)
            const localVarPath = `/api/tyres/aspectratios/{sectionWidth}`
                .replace(`{${"sectionWidth"}}`, encodeURIComponent(String(sectionWidth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} aspectRatio 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [sectionWidth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresRimsizesAspectRatioGet: async (aspectRatio: string, pageNo?: number, pageSize?: number, sectionWidth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aspectRatio' is not null or undefined
            assertParamExists('apiTyresRimsizesAspectRatioGet', 'aspectRatio', aspectRatio)
            const localVarPath = `/api/tyres/rimsizes/{aspectRatio}`
                .replace(`{${"aspectRatio"}}`, encodeURIComponent(String(aspectRatio)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sectionWidth !== undefined) {
                localVarQueryParameter['sectionWidth'] = sectionWidth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresSectionwidthsGet: async (pageNo?: number, pageSize?: number, isImperial?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tyres/sectionwidths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (isImperial !== undefined) {
                localVarQueryParameter['isImperial'] = isImperial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresTyresizesGet: async (pageNo?: number, pageSize?: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tyres/tyresizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TyresApi - functional programming interface
 * @export
 */
export const TyresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TyresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {OrderByDirection} [orderByDirection] 
         * @param {GetTyresQueryOrderByField} [orderByField] 
         * @param {number} [minimumPrice] 
         * @param {number} [maximumPrice] 
         * @param {boolean} [isRunFlat] 
         * @param {Array<number>} [tyreBrandIds] 
         * @param {Array<number>} [tyreCategoryIds] 
         * @param {string} [sectionWidth] 
         * @param {string} [aspectRatio] 
         * @param {string} [rimSize] 
         * @param {number} [treadPatternId] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyreGet(pageNo?: number, pageSize?: number, orderByDirection?: OrderByDirection, orderByField?: GetTyresQueryOrderByField, minimumPrice?: number, maximumPrice?: number, isRunFlat?: boolean, tyreBrandIds?: Array<number>, tyreCategoryIds?: Array<number>, sectionWidth?: string, aspectRatio?: string, rimSize?: string, treadPatternId?: number, isImperial?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfTyreListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyreGet(pageNo, pageSize, orderByDirection, orderByField, minimumPrice, maximumPrice, isRunFlat, tyreBrandIds, tyreCategoryIds, sectionWidth, aspectRatio, rimSize, treadPatternId, isImperial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} sectionWidth 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyresAspectratiosSectionWidthGet(sectionWidth: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyresAspectratiosSectionWidthGet(sectionWidth, pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} aspectRatio 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [sectionWidth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyresRimsizesAspectRatioGet(aspectRatio: string, pageNo?: number, pageSize?: number, sectionWidth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyresRimsizesAspectRatioGet(aspectRatio, pageNo, pageSize, sectionWidth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyresSectionwidthsGet(pageNo?: number, pageSize?: number, isImperial?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyresSectionwidthsGet(pageNo, pageSize, isImperial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTyresTyresizesGet(pageNo?: number, pageSize?: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTyresTyresizesGet(pageNo, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TyresApi - factory interface
 * @export
 */
export const TyresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TyresApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {OrderByDirection} [orderByDirection] 
         * @param {GetTyresQueryOrderByField} [orderByField] 
         * @param {number} [minimumPrice] 
         * @param {number} [maximumPrice] 
         * @param {boolean} [isRunFlat] 
         * @param {Array<number>} [tyreBrandIds] 
         * @param {Array<number>} [tyreCategoryIds] 
         * @param {string} [sectionWidth] 
         * @param {string} [aspectRatio] 
         * @param {string} [rimSize] 
         * @param {number} [treadPatternId] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyreGet(pageNo?: number, pageSize?: number, orderByDirection?: OrderByDirection, orderByField?: GetTyresQueryOrderByField, minimumPrice?: number, maximumPrice?: number, isRunFlat?: boolean, tyreBrandIds?: Array<number>, tyreCategoryIds?: Array<number>, sectionWidth?: string, aspectRatio?: string, rimSize?: string, treadPatternId?: number, isImperial?: boolean, options?: any): AxiosPromise<PagedResultOfTyreListViewModel> {
            return localVarFp.apiTyreGet(pageNo, pageSize, orderByDirection, orderByField, minimumPrice, maximumPrice, isRunFlat, tyreBrandIds, tyreCategoryIds, sectionWidth, aspectRatio, rimSize, treadPatternId, isImperial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} sectionWidth 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresAspectratiosSectionWidthGet(sectionWidth: string, pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiTyresAspectratiosSectionWidthGet(sectionWidth, pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} aspectRatio 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [sectionWidth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresRimsizesAspectRatioGet(aspectRatio: string, pageNo?: number, pageSize?: number, sectionWidth?: string, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiTyresRimsizesAspectRatioGet(aspectRatio, pageNo, pageSize, sectionWidth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {boolean} [isImperial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresSectionwidthsGet(pageNo?: number, pageSize?: number, isImperial?: boolean, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiTyresSectionwidthsGet(pageNo, pageSize, isImperial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTyresTyresizesGet(pageNo?: number, pageSize?: number, searchTerm?: string, options?: any): AxiosPromise<PagedResultOfString> {
            return localVarFp.apiTyresTyresizesGet(pageNo, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TyresApi - object-oriented interface
 * @export
 * @class TyresApi
 * @extends {BaseAPI}
 */
export class TyresApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {OrderByDirection} [orderByDirection] 
     * @param {GetTyresQueryOrderByField} [orderByField] 
     * @param {number} [minimumPrice] 
     * @param {number} [maximumPrice] 
     * @param {boolean} [isRunFlat] 
     * @param {Array<number>} [tyreBrandIds] 
     * @param {Array<number>} [tyreCategoryIds] 
     * @param {string} [sectionWidth] 
     * @param {string} [aspectRatio] 
     * @param {string} [rimSize] 
     * @param {number} [treadPatternId] 
     * @param {boolean} [isImperial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyresApi
     */
    public apiTyreGet(pageNo?: number, pageSize?: number, orderByDirection?: OrderByDirection, orderByField?: GetTyresQueryOrderByField, minimumPrice?: number, maximumPrice?: number, isRunFlat?: boolean, tyreBrandIds?: Array<number>, tyreCategoryIds?: Array<number>, sectionWidth?: string, aspectRatio?: string, rimSize?: string, treadPatternId?: number, isImperial?: boolean, options?: AxiosRequestConfig) {
        return TyresApiFp(this.configuration).apiTyreGet(pageNo, pageSize, orderByDirection, orderByField, minimumPrice, maximumPrice, isRunFlat, tyreBrandIds, tyreCategoryIds, sectionWidth, aspectRatio, rimSize, treadPatternId, isImperial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} sectionWidth 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyresApi
     */
    public apiTyresAspectratiosSectionWidthGet(sectionWidth: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return TyresApiFp(this.configuration).apiTyresAspectratiosSectionWidthGet(sectionWidth, pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} aspectRatio 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [sectionWidth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyresApi
     */
    public apiTyresRimsizesAspectRatioGet(aspectRatio: string, pageNo?: number, pageSize?: number, sectionWidth?: string, options?: AxiosRequestConfig) {
        return TyresApiFp(this.configuration).apiTyresRimsizesAspectRatioGet(aspectRatio, pageNo, pageSize, sectionWidth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {boolean} [isImperial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyresApi
     */
    public apiTyresSectionwidthsGet(pageNo?: number, pageSize?: number, isImperial?: boolean, options?: AxiosRequestConfig) {
        return TyresApiFp(this.configuration).apiTyresSectionwidthsGet(pageNo, pageSize, isImperial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyresApi
     */
    public apiTyresTyresizesGet(pageNo?: number, pageSize?: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return TyresApiFp(this.configuration).apiTyresTyresizesGet(pageNo, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleManufacturersApi - axios parameter creator
 * @export
 */
export const VehicleManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleManufacturerGet: async (pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vehicle-manufacturer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleManufacturersApi - functional programming interface
 * @export
 */
export const VehicleManufacturersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleManufacturersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVehicleManufacturerGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfVehicleManufacturerListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVehicleManufacturerGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleManufacturersApi - factory interface
 * @export
 */
export const VehicleManufacturersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleManufacturersApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleManufacturerGet(pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfVehicleManufacturerListViewModel> {
            return localVarFp.apiVehicleManufacturerGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleManufacturersApi - object-oriented interface
 * @export
 * @class VehicleManufacturersApi
 * @extends {BaseAPI}
 */
export class VehicleManufacturersApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleManufacturersApi
     */
    public apiVehicleManufacturerGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return VehicleManufacturersApiFp(this.configuration).apiVehicleManufacturerGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleModelYearsApi - axios parameter creator
 * @export
 */
export const VehicleModelYearsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleModelYearVehicleModelIdGet: async (vehicleModelId: string, pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleModelId' is not null or undefined
            assertParamExists('apiVehicleModelYearVehicleModelIdGet', 'vehicleModelId', vehicleModelId)
            const localVarPath = `/api/vehicle-model-year/{vehicleModelId}`
                .replace(`{${"vehicleModelId"}}`, encodeURIComponent(String(vehicleModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleModelYearsApi - functional programming interface
 * @export
 */
export const VehicleModelYearsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleModelYearsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVehicleModelYearVehicleModelIdGet(vehicleModelId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfVehicleModelYearListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVehicleModelYearVehicleModelIdGet(vehicleModelId, pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleModelYearsApi - factory interface
 * @export
 */
export const VehicleModelYearsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleModelYearsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleModelYearVehicleModelIdGet(vehicleModelId: string, pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfVehicleModelYearListViewModel> {
            return localVarFp.apiVehicleModelYearVehicleModelIdGet(vehicleModelId, pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleModelYearsApi - object-oriented interface
 * @export
 * @class VehicleModelYearsApi
 * @extends {BaseAPI}
 */
export class VehicleModelYearsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} vehicleModelId 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModelYearsApi
     */
    public apiVehicleModelYearVehicleModelIdGet(vehicleModelId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return VehicleModelYearsApiFp(this.configuration).apiVehicleModelYearVehicleModelIdGet(vehicleModelId, pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleModelsApi - axios parameter creator
 * @export
 */
export const VehicleModelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleManufacturerId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleModelVehicleManufacturerIdGet: async (vehicleManufacturerId: string, pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleManufacturerId' is not null or undefined
            assertParamExists('apiVehicleModelVehicleManufacturerIdGet', 'vehicleManufacturerId', vehicleManufacturerId)
            const localVarPath = `/api/vehicle-model/{vehicleManufacturerId}`
                .replace(`{${"vehicleManufacturerId"}}`, encodeURIComponent(String(vehicleManufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleModelsApi - functional programming interface
 * @export
 */
export const VehicleModelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleModelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleManufacturerId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfVehicleModelListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId, pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleModelsApi - factory interface
 * @export
 */
export const VehicleModelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleModelsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleManufacturerId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId: string, pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfVehicleModelListViewModel> {
            return localVarFp.apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId, pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleModelsApi - object-oriented interface
 * @export
 * @class VehicleModelsApi
 * @extends {BaseAPI}
 */
export class VehicleModelsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} vehicleManufacturerId 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleModelsApi
     */
    public apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return VehicleModelsApiFp(this.configuration).apiVehicleModelVehicleManufacturerIdGet(vehicleManufacturerId, pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleSpecificationsApi - axios parameter creator
 * @export
 */
export const VehicleSpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelYearId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleSpecificationVehicleModelYearIdGet: async (vehicleModelYearId: string, pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleModelYearId' is not null or undefined
            assertParamExists('apiVehicleSpecificationVehicleModelYearIdGet', 'vehicleModelYearId', vehicleModelYearId)
            const localVarPath = `/api/vehicle-specification/{vehicleModelYearId}`
                .replace(`{${"vehicleModelYearId"}}`, encodeURIComponent(String(vehicleModelYearId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleSpecificationsApi - functional programming interface
 * @export
 */
export const VehicleSpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleSpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelYearId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfVehicleSpecificationListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId, pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleSpecificationsApi - factory interface
 * @export
 */
export const VehicleSpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleSpecificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} vehicleModelYearId 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId: string, pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfVehicleSpecificationListViewModel> {
            return localVarFp.apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId, pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleSpecificationsApi - object-oriented interface
 * @export
 * @class VehicleSpecificationsApi
 * @extends {BaseAPI}
 */
export class VehicleSpecificationsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} vehicleModelYearId 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleSpecificationsApi
     */
    public apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId: string, pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return VehicleSpecificationsApiFp(this.configuration).apiVehicleSpecificationVehicleModelYearIdGet(vehicleModelYearId, pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


